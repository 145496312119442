import { Injectable } from '@angular/core';
import { environment as env } from '@environments/environment';
import { HubspotRequest } from '@models/integrations/hubspot/dto/hubspot.request';
import { ApiService } from '@services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HubspotService {
  private readonly authorizeUrl = 'https://app.hubspot.com/oauth/authorize';
  private readonly clientId;
  private readonly redirectUri;
  private readonly scope = 'crm.objects.contacts.write crm.schemas.contacts.write oauth crm.schemas.contacts.read crm.objects.contacts.read';

  constructor(private api: ApiService) {
    this.clientId = this.getClientId();
    this.redirectUri = this.getRedirectUri();
  }

  private getClientId(): string {
    switch (env.environmentName) {
      case 'dev':
        return '446a02e3-7819-47be-aa20-b3c25d54c1af';
      case 'alpha':
        return '446a02e3-7819-47be-aa20-b3c25d54c1af';
      default:
        return '4662d061-b0c5-4d77-9d86-e65c3a9ab825';
    }
  }

  private getRedirectUri(): string {
    switch (env.environmentName) {
      case 'dev':
        return 'http://localhost:4200/hubspot/oauth/callback';
      case 'alpha':
        return 'https://alpha.eventpage.ai/hubspot/oauth/callback';
      default:
        return `https://app.${env.platform}/hubspot/oauth/callback`;
    }
  }

  redirectToHubspotAuth(state: string): void {
    window.location.href = `${this.authorizeUrl}?client_id=${this.clientId}&redirect_uri=${encodeURIComponent(this.redirectUri)}&scope=${encodeURIComponent(this.scope)}&state=${encodeURIComponent(state)}`;
  }

  connect(req: HubspotRequest): Observable<void> {
    return this.api.post(`${env.api}/users/integrations/hubspot`, req);
  }

  disconnect(): Observable<void> {
    return this.api.get(`${env.api}/users/integrations/hubspot/disconnect`);
  }
}
